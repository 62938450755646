import React from "react";
import PropTypes from "prop-types";
import UserGreeting from "./UserGreeting.jsx";
import LoginButton from "./LoginButton.jsx";

export default class UserPresence extends React.Component {
  constructor(props) {
    super(props);
    this.user_cookie = this.getCookie("STYXKEY_nber_user_info").split('|');
    this.ticket_cookie = this.getCookie("STYXKEY_username_ticket_cookie");
    this.state = {
      error: false,
      username: null,
      is_scholar: false,
      level: 0
    };
  }

  /**
   * Only perform a fetch if the ticket cookie is present and the user cookie is not or has expired.
   */
  componentDidMount() {
    // Fetch an update and set state.
    if (this.validCookie(this.ticket_cookie, "string") && !(this.validCookie(this.user_cookie, "object"))) {
      fetch(this.props.api_url, {credentials: "include"})
        .then((response) => {
            if (!response.ok) {
              this.setState({error: true});
            } else {
              response.text().then((data) => {
                let items = {};
                if (data) {
                  items = JSON.parse(data);
                }
                this.setState({
                  username: items["firstname"],
                  is_scholar: items["affiliate"],
                  level: items["alert_level"]
                });
              });
            }
          }
        )
        .catch((err) => {
          this.setState({error: true});
          console.log("Error communicating with user service.");
        });
    }

    // Set the state if both cookies are present.
    if (this.validCookie(this.ticket_cookie, "string") && (this.validCookie(this.user_cookie, "object"))) {
      this.setState({
        username: this.getUsername(),
        is_scholar: this.isScholar(),
        level: this.getAlertLevel()
      });
    }
  }

  getCookie(name) {
    return ("; " + document.cookie).split("; " + name + "=").pop().split("; ").shift();
  }

  validCookie(cookie, value_type) {
    return (typeof cookie === value_type && cookie !== null && cookie.length > 1);
  }

  getUsername() {
    return this.user_cookie[0];
  }

  isScholar() {
    return Boolean(this.user_cookie[1]);
  }

  getAlertLevel() {
    return parseInt(this.user_cookie[2]);
  }

  render() {
    let button;

    if (!this.state.error && this.validCookie(this.ticket_cookie, "string") && this.state.username) {
      let props = {
        username: this.state.username,
        is_scholar: this.state.is_scholar,
        level: this.state.level,
      };

      button = <UserGreeting {...props} />;
    } else {
      button = <LoginButton />;
    }

    return (
      <div className="user-block">
        { button }
      </div>
    );
  }
}

const { string } = PropTypes;

UserPresence.propTypes= {
  api_url: string,
};

UserPresence.defaultProps = {
  api_url: "https://my.nber.org/set_user_info_cookie"
};