import $ from "jquery";

/*
 * AccessibleLabels
 * Provide hooks for styling an accessible label that gives the appearance of being placeholder text
 */
export class AccessibleLabels {
  constructor($el) {
    this.$el = $el;
    this.$label = this.$el.find("label");
    this.$input = this.$el.find("input").length ? this.$el.find("input") : this.$el.find("textarea");

    if (this.$input.val().length < 1) {
      this.$el.addClass("form__field--placeholder");
    }

    this.bindEvents();
  }

  bindEvents() {
    let thisLabel = this;

    thisLabel.$input.on("focus", function() {
      thisLabel.$el.addClass("is-dirty");
    });

    thisLabel.$input.on("blur", function() {
      if (thisLabel.$input.val().length < 1) {
        thisLabel.$el.removeClass("is-dirty");
      }
      else {
        thisLabel.$el.addClass("is-dirty");
      }
    });
  }
}
