import ReactDOM from "react-dom";
import React from "react";
import UserPresence from "./components/UserPresence.jsx";

export const loginInit = () => {
  const elements = document.getElementsByClassName('header__user');

  for (var i=0; i<elements.length; i++) {
    ReactDOM.render(
      <UserPresence />,
      elements.item(i)
    );
  }
}
