import "./polyfill";
import $ from "jquery";

// import { Alert } from "./nber/alerts/Alert";
// import { AnimatedHeader } from "./nber/animations/AnimatedHeader";
import { AccessibleLabels } from "./nber/forms/AccessibleLabels";
// import { PasswordToggle } from "./nber/forms/PasswordToggle"; 
// import { ClickableCard } from "./nbaer/helpers/ClickableCard";
import { SimpleAccordion } from "./nber/accordions/SimpleAccordion";
import PrimaryNav from "./nber/modules/PrimaryNav.js";
import { Hamburger } from "./nber/modules/Hamburger.js";
// import { AffiliatesSlideshow } from "./nber/sliders/AffiliatesSlideshow.js";
// import { listingInit } from "./nber/search/index.js";
// import { sitewideAlert } from "./nber/sitewide-alert/index.js";
// import { PauseAllAnimations } from "./nber/animations/PauseAllAnimations";
import { BackToTop } from "./nber/animations/BackToTop";
// import initSearchMenu from "./nber/modules/initSearchMenu";
import { loginInit } from "./nber/user/index.js";
// import { agendaInit } from "./nber/conference-agenda/index.js";

// AccessibleLabels
const $accessiblelabel = $(".js-label");
if ($accessiblelabel.length) {
  const accessiblelabel = $.makeArray($accessiblelabel).map( el => {
    return new AccessibleLabels($(el));
  });
}

// Simple accordions
// const $simpleaccordions = $(".js-accordion");
// if ($simpleaccordions.length) {
//   const simpleaccordions = $.makeArray($simpleaccordions).map( el => {
//     return new SimpleAccordion($(el), false);
//   });
// }

const $navaccordions = $(".js-nav-accordion");
if ($navaccordions.length) {
  const navaccordions = $.makeArray($navaccordions).map( el => {
    return new SimpleAccordion($(el), ["small"]);
  });
}

// Primary Nav
const $primaryNavItems = $('.js-primary-nav').find('.primary-nav__item');
if ($primaryNavItems.length > 0) {
  const $subNavItems = $('.js-primary-nav').find('.primary-nav__subitem, .primary-nav__dropdown-link, .primary-nav__sublist-heading.link--arrow');
  PrimaryNav($primaryNavItems, $subNavItems);
}

// Mobile nav
const $hamburgers = $(".js-hamburger");
if ($hamburgers.length) {
  const hamburgers = $.makeArray($hamburgers).map( el => {
    return new Hamburger($(el), true, true, ".header__user--mobile", ".header__utility--mobile");
  });
}

loginInit();
