import React from "react";
import { GetIcon } from "../icons/GetIcon";

export default class LoginButton extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <a href="https://my.nber.org/" className="user-block__login"><span>Login</span> { GetIcon() }</a>
    );
  }
}