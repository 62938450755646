import $ from "jquery";

export default function PrimaryNav(els, subEls) {
  const keyCodes = {
    tab: 9,
    up: 38,
    down: 40,
    left: 37,
    right: 39,
    enter: 13,
    space: 32,
    escape: 27,
  };

  var currentIndex, subIndex;

  const preventAll = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  $(els).each(function(idx, navitem) {
    const self = $(navitem);
    const button = self.find('.primary-nav__action');

    if (self.hasClass('primary-nav__item--has-children')) {
      button.click(function(e) {
        toggleDropdown(self);
        preventAll(e);
      });
    }

    self.find('.primary-nav__action').focus(function() {
      if (idx === 0) {
        // $(navitem).attr("tabindex", "0");
        currentIndex = 0;
      }
      else {
        // $(navitem).attr("tabindex", "-1");
      }
      subIndex = 0;
      $(els).each(function(navitem) {
        self.find('.js-primary-nav__action').attr('aria-expanded', 'false');
      });
    });

    self.on('keydown', function(e) {
      switch (e.keyCode) {
        case keyCodes.right:
          closeDropdown(self);
          gotoIndex(currentIndex + 1);
          preventAll(e);
          break;
        case keyCodes.left:
          closeDropdown(self);
          gotoIndex(currentIndex - 1);
          preventAll(e);
          break;
        case keyCodes.tab:
          const futureIndex = e.shiftKey ? currentIndex - 1 : currentIndex + 1;
          closeDropdown(self);
          if (els[futureIndex]) {
            gotoIndex(futureIndex);
            preventAll(e);
          }
          break;
        case keyCodes.down:
          openDropdown(self);
          subIndex = 0;
          gotoSubIndex(self.find('.primary-nav__dropdown-body'), 0);
          preventAll(e);
          break;
        // case keyCodes.up:
        //   openDropdown(self);
        //   const submenu = self.find(".primary-nav__sublist");
        //   const submenuIndex = submenu.find(".primary-nav__subitem").length - 1;
        //   gotoSubIndex(submenu, submenuIndex);
        //   e.preventDefault();
        //   break;
        case keyCodes.escape:
          closeDropdown(self);
          preventAll(e);
          break;
      }
    });
  });

  $(subEls).each(function(idx, subnavitem) {
    const self = $(subnavitem);
    self.on('keydown', function(e) {
      switch (e.keyCode) {
        case keyCodes.right:
          closeDropdown(self.parents('.primary-nav__item'));
          gotoIndex(currentIndex + 1);
          preventAll(e);
          break;
        case keyCodes.left:
          closeDropdown(self.parents('.primary-nav__item'));
          gotoIndex(currentIndex - 1);
          preventAll(e);
          break;
        case keyCodes.tab:
          closeDropdown(self.parents('.primary-nav__item'));
          if (e.shiftKey) {
            gotoIndex(currentIndex - 1);
          }
          else {
            gotoIndex(currentIndex + 1);
          }
          preventAll(e);
          break;
        case keyCodes.down:
          gotoSubIndex(self.parents('.primary-nav__dropdown-body'), subIndex + 1);
          preventAll(e);
          break;
        case keyCodes.up:
          gotoSubIndex(self.parents('.primary-nav__dropdown-body'), subIndex - 1);
          preventAll(e);
          break;
      }
    });
  });

  function gotoIndex(idx) {
    if (els[idx] !== null && els[idx] !== undefined) {
      els[idx].querySelector('.primary-nav__action').focus();
      currentIndex = idx;
    }
  }

  function gotoSubIndex(menu, idx) {
    const items = menu.find('.primary-nav__subitem, .primary-nav__dropdown-link, .primary-nav__sublist-heading.link--arrow');
    // console.log(items);
    // check if subitem exists
    if (items.length === 0) {
      return;
    }

    if (idx === items.length) {
      idx = 0;
    }
    else if (idx < 0) {
      idx = items.length - 1;
    }

    if ($(items[idx]).is('a')) {
      items[idx].focus();
    }
    else {
      items[idx].querySelector('a').focus();
    }
    subIndex = idx;
  }

  function toggleDropdown(element) {
    if (element.hasClass('primary-nav__item--open')) {
      closeDropdown(element);
    } else {
      openDropdown(element);
    }

    if (element.siblings().hasClass('primary-nav__item--open')) {
      closeDropdownSiblings(element);
    }
  }

  function openDropdown(element) {
    element.addClass('primary-nav__item--open');
    element.find('[aria-expanded=false]').attr('aria-expanded', 'true');
  }

  function closeDropdown(element) {
    element.find('[aria-expanded=true]').focus();
    element.removeClass('primary-nav__item--open');
    element.find('[aria-expanded=true]').attr('aria-expanded', 'false');
  }

  function closeDropdownSiblings(element) {
    element.siblings().removeClass('primary-nav__item--open');
    element.siblings().find('[aria-expanded=true]').attr('aria-expanded', 'false');
  }

  // global close all if clicked outside the super nav
  $(document).click(function(e) {
    if ($(els) !== e.target && !$(els).has(e.target).length) {
      if ($(els).hasClass('primary-nav__item--open')) {
        closeDropdown($(els));
      }
    }
  });

  // $(els).on("keydown", function (e) {
  //   switch (e.keyCode) {
      // case keyCodes.escape:
      //     closeDropdown($(els));
      //     break;
    //   default:
    // }
  // });
}
