import $ from "jquery";
import { breakpoint } from "../breakpoint";

let throttler;
const keyCodes = {
  tab: 9,
  up: 38,
  down: 40,
  left: 37,
  right: 39,
  enter: 13,
  space: 32,
  escape: 27
};

/*
 * Trigger class for Hamburger-like menues
 * params:
 *  $el: jQuery element, burger menu trigger
 *  skipTriggerInTab: when true, confine tabbing behavior to target and
 *    skip trigger (trigger is not a toggle)
 *  preventBodyScroll: if true, locks body on burger open
 *  firstTabbableParent: selector, optional.  Where to look for the first
 *    tabbable element if some tabbable elements are hidden
 *  lastTabbableParent: selector, optional.  Where to look for the last
 *    tabbable element if some tabbable elements are hidden
 */
export class Hamburger {
  constructor($el, skipTriggerInTab, preventBodyScroll, firstTabbableParent, lastTabbableParent) {
    this.$el = $el;
    this.skipTriggerInTab = skipTriggerInTab;
    this.preventBodyScroll = preventBodyScroll;
    this.$target = $("#" + this.$el.data("target"));   // Body of menu
    this.$focus = this.$el.data("focus")
      ? $("#" + this.$el.data("focus"))
      : null; // Element in menu to focus on open
    this.$targetClose = this.$target.find(".js-hamburger-close"); // Close button
    this.inMenuMode = false;

    /* Find legit focusable elements... doing this instead of adding jQuery UI because we're not using it elsewhere */
    this.$targetsFirstTabbable = firstTabbableParent
      ? this.$target.find(firstTabbableParent).find("a, button, :input, [tabindex]").last()
      : this.$target.find("a, button, :input, [tabindex]").first();
    this.$targetsLastTabbable = lastTabbableParent
      ? this.$target.find(lastTabbableParent).find("a, button, :input, [tabindex]").last()
      : this.$target.find("a, button, :input, [tabindex]").last();

    this.manageBreakpointBehaviors();
    this.bindEvents();
  }

  bindEvents() {
    let thisMenu = this;

    /* Esc key-triggered menu close */
    this.$target.on("keydown", function(e) {
      if (e.keyCode === keyCodes.escape && thisMenu.inMenuMode) {
        e.preventDefault();
        thisMenu.$target.hide().attr("aria-hidden", "true");
        thisMenu.$el.removeClass("is-open").focus(); /* Skip focus back to button and stay there due to preventDefault */
        thisMenu.$target.removeClass("is-open"); /* Skip focus back to button and stay there due to preventDefault */
        thisMenu.$el.attr("aria-expanded", "false");
        thisMenu.inMenuMode = false;
        thisMenu.$el.attr("aria-expanded", "false");
        $("body").removeClass("is-scroll-frozen");
      }
    });


    /* Key triggered button close */
    this.$targetClose.on("keydown", function(e) {
      e.stopPropagation();

      switch (e.keyCode) {
        case keyCodes.escape:
        case keyCodes.enter:
        case keyCodes.space:
          /*
            It's possible for multiple triggers to target one menu, so
            ONLY trigger the close behaviors and focus for the currently active trigger
           */
          if (thisMenu.inMenuMode) {
            e.preventDefault();
            thisMenu.$target.hide().attr("aria-hidden", "true");
            thisMenu.$el.attr("aria-expanded", "false");
            thisMenu.$el.removeClass("is-open");
            thisMenu.$target.removeClass("is-open");
            thisMenu.$el.attr("aria-expanded", "false");
            thisMenu.$el.focus();
            thisMenu.inMenuMode = false;
            $("body").removeClass("is-scroll-frozen");
          }
          break;
        default:
      }
    });

    this.$el.on("keyup", function(e) {
      e.preventDefault(); /* workaround for FF bug */
    });

    this.$targetsLastTabbable.on("keyup", function(e) {
      e.preventDefault(); /* workaround for FF bug */
    });

    this.$targetsFirstTabbable.on("keyup", function(e) {
      e.preventDefault(); /* workaround for FF bug */
    });

    this.$targetClose.on("keyup", function(e) {
      e.preventDefault(); /* workaround for FF bug */
    });

    /* Click trigger for button close */
    this.$targetClose.on("click", function(e) {
      e.preventDefault();

      if (thisMenu.inMenuMode) {
        thisMenu.$target.hide().attr("aria-hidden", "true");
        thisMenu.$el.attr("aria-expanded", "false");
        thisMenu.$el.removeClass("is-open");
        thisMenu.$target.removeClass("is-open");
        thisMenu.$el.attr("aria-expanded", "false");
        thisMenu.$el.focus();
        thisMenu.inMenuMode = false;
        $("body").removeClass("is-scroll-frozen");
      }
    });

    /* Keyboard trap for tab, forward tabbing */
    this.$targetsLastTabbable.on("keydown", function(e) {
      if (e.keyCode == keyCodes.tab && !e.shiftKey && thisMenu.inMenuMode) {
        e.preventDefault();
        if(thisMenu.skipTriggerInTab) {
          thisMenu.$targetsFirstTabbable.focus(); /* Re-focus first item */
        }
        else {
          thisMenu.$el.focus(); /* Skip focus back to button and stay there due to preventDefault */
        }
      }
    });

    /* Keyboard trap for tab, backward tabbing */
    this.$targetsFirstTabbable.on("keydown", function(e) {
      if (e.keyCode === keyCodes.tab && e.shiftKey && thisMenu.inMenuMode) {
        e.preventDefault();
        if(thisMenu.skipTriggerInTab) {
          thisMenu.$targetsLastTabbable.focus(); /* Re-focus first item */
        }
        else {
          thisMenu.$el.focus(); /* Skip focus back to button and stay there due to preventDefault */
        }
      }
    });

    /* Key controls for burger open trigger */
    this.$el.on("keydown", function(e) {
      switch (e.keyCode) {
        case keyCodes.tab:
          if (e.shiftKey && thisMenu.inMenuMode) {
            e.preventDefault();
            thisMenu.$targetsLastTabbable.focus();
          }
          break;
        case keyCodes.escape:
          e.preventDefault();
          thisMenu.$target.hide().attr("aria-hidden", "true");
          thisMenu.$el.attr("aria-expanded", "false");
          thisMenu.$el.removeClass("is-open");
          thisMenu.$target.removeClass("is-open");
          thisMenu.$el.attr("aria-expanded", "false");
          break;
        case keyCodes.enter:
        case keyCodes.space:
          e.preventDefault();

          thisMenu.$target.toggle().attr("aria-hidden", thisMenu.getHiddenState());
          thisMenu.$el.attr("aria-expanded", !thisMenu.getHiddenState());
          thisMenu.$el.toggleClass("is-open");
          thisMenu.$target.toggleClass("is-open");
          thisMenu.$el.attr("aria-expanded", "true");
          thisMenu.inMenuMode = !thisMenu.inMenuMode;
          if (thisMenu.preventBodyScroll) {
            $("body").toggleClass("is-scroll-frozen");
          }
          if (thisMenu.inMenuMode) {
            if (thisMenu.$focus) {
              thisMenu.$focus.focus();
            }
            else {
              thisMenu.$targetsFirstTabbable.focus();
            }
          }
          break;
        default:
      }
    });

    /* Click controls for trigger open */
    this.$el.on("click", function() {
      thisMenu.$target.toggle().attr("aria-hidden", thisMenu.getHiddenState());
      thisMenu.$el.attr("aria-expanded", !thisMenu.getHiddenState());
      thisMenu.$el.toggleClass("is-open");
      thisMenu.$target.toggleClass("is-open");
      thisMenu.$el.attr("aria-expanded", "true");
      thisMenu.inMenuMode = !thisMenu.inMenuMode;
      if (thisMenu.preventBodyScroll) {
        $("body").toggleClass("is-scroll-frozen");
      }

      if (thisMenu.inMenuMode) {
        if (thisMenu.$focus) {
          thisMenu.$focus.focus();
        }
        else {
          thisMenu.$targetsFirstTabbable.focus();
        }
      }
    });

    $(window).on("resize", event => {
      if (throttler) {
        window.clearTimeout(throttler);
      }
      throttler = setTimeout(() => {
        this.manageBreakpointBehaviors();
      }, 400);
    });
  }

  /* Helper function to grab current open state */
  getHiddenState() {
    return this.$target.attr("aria-hidden") === "true" ? "false" : "true";
  }

  /*
    Handle changes on breakpoint.
    In general, we want to close/hide the menu as appropriate when switching between
      major breakpoints, as well as set or unset the appropriate aria attributes
  */
  manageBreakpointBehaviors() {
    if ((breakpoint() === "small" ||  breakpoint() === "medium") && this.$el.hasClass("is-open")) {
      this.$target.attr("aria-hidden", "false");
      this.$el.attr("aria-expanded", "true");
      this.inMenuMode = true;
    }
    else if ((breakpoint() === "small" ||  breakpoint() === "medium")) {
      this.$target.hide().attr("aria-hidden", "true").css("display", "");
      this.inMenuMode = false;
    }
    else {
      this.$target.show().attr("aria-hidden", "false").css("display", "");
      this.inMenuMode = false;
      this.$el.attr("aria-expanded", "false");
      this.$el.removeClass("is-open");
      this.$target.removeClass("is-open");
      this.$el.attr("aria-expanded", "false");
      $("body").removeClass("is-scroll-frozen");
    }
  }
}
