import $ from "jquery";

let prevPosition,
	recentScroll = false;

$(window).scroll(function() {
	let position = $(this).scrollTop();
	if (!recentScroll && (!prevPosition || position == 0 || prevPosition == 0)) {
		$('.back-to-top').off().toggleClass('active').fadeToggle();
		$('.back-to-top.active').click(function() {
			$(window).scrollTop(0);
		});
		recentScroll = true;
		window.setTimeout(function() {
			recentScroll = false;
		}, 100);
	}
	prevPosition = position;
});
