import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { GetIcon } from "../icons/GetIcon";

export default class UserGreeting extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { username, level, is_scholar } = this.props;

    const alertClass = classNames("user-block__notice user-alert-level-" + level);

    return (
      <div className="user-block__greeting"><a href="https://my.nber.org/" className="user-block__login"><span className="user-block__greeting">Welcome, {username}!</span> { GetIcon() }</a>
        <span className={alertClass}></span>
      </div>
    );
  }
}

const { number, string, bool } = PropTypes;

UserGreeting.propTypes= {
  username: string,
  level: number,
  is_scholar: bool,
};