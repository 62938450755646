import React from "react";

export const GetIcon = () => {
  return(<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="21" height="20" viewBox="0 0 21 20">
    <g>
      <g transform="translate(-1296 -100)">
        <path d="M1307.938 113.561v-1.237c1.583-.931 2.874-3.252 2.874-5.574 0-3.728 0-6.75-4.312-6.75-4.313 0-4.313 3.022-4.313 6.75 0 2.322 1.292 4.643 2.876 5.574v1.237c-4.877.416-8.626 2.916-8.626 5.939h20.126c0-3.023-3.75-5.523-8.626-5.939z"/>
      </g>
    </g>
  </svg>);
}
